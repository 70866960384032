import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from "@/plugins/i18n";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/:mask',
    params: 'mask',
    name: 'camera',
    component: () => import('../views/CameraNew.vue')
  }
]

const router = new VueRouter({
 // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  console.log('to',to)
  console.log('from',from)

  let parentsRoute = to.matched
  to.meta.breadCrumbs = []
  to.meta.analytics = to.name

  parentsRoute.forEach(parent => {
    to.meta.breadCrumbs.push({text:parent.name,href:''+parent.path})

    var title = i18n.t('router.'+parent.name+'.title');

    if(to.params){
      if(to.params.mask){
         title = title +' - '+ to.params.mask;
      }
    }

    document.title = title
    document.description = i18n.t('router.'+parent.name+'.description');
  });

  var metaThemeColor = document.createElement('meta');
    metaThemeColor.setAttribute('name', 'theme-color');
    metaThemeColor.setAttribute('id', 'theme-color');
    metaThemeColor.content = "#ED0E1B";

  document.querySelector('meta[name="theme-color"]').remove();
  document.getElementsByTagName('head')[0].appendChild(metaThemeColor);

  next()

})

export default router
