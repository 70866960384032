import it from './it'

var lang = (localStorage.getItem('language'))  ? localStorage.getItem('language') : navigator.language.substr(0,2);

if(!lang){
    lang = "it"
}

localStorage.setItem('language', lang)

export const defaultLocale = lang;

export const languages = {
    it: it,
}