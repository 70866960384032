import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
     theme: {
          themes: {
             light: {
               primary: "#ED0E1B",
               secondary: "#FEF100", // #BE9949
               light: "#FFFFFF", // #FFFFFF
             },
          },
     },
});
